<template>
  <b-card>

    <b-row>

      <!-- User Info: Left col -->
      <b-col
        cols="21"
        xl="6"
        class="d-flex justify-content-between flex-column"
      >
        <!-- User Avatar & Action Buttons -->
        <div class="d-flex justify-content-start">
          <b-avatar
            :text="avatarText(credorData.data.nome)"
            :variant="`light-primary`"
            size="104px"
            rounded
          />
          <div class="d-flex flex-column ml-1">
            <div class="mb-1">
              <h4 class="mb-0">
                {{ credorData.data.nome }}
              </h4>
              <span class="card-text">{{ credorData.data.email }}</span>
            </div>
            <div class="d-flex flex-wrap">
              <b-button
                variant="primary"
                 :to="{ name: 'credor-edit', params: { id: credorData.data.id } }"
              >
                Editar
              </b-button>
              <!-- <b-button
                variant="outline-danger"
                class="ml-1"
              >
                Deletar
              </b-button> -->
            </div> 
          </div>
        </div>
      </b-col>

      <!-- Right Col: Table -->
      <b-col
        cols="12"
        xl="6"
      >
        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="UserIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">CNPJ</span>
            </th>
            <td class="pb-50">
              {{ credorData.data.cnpj }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="CheckIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Codigo</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ credorData.data.codigo }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="MailIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Email</span>
            </th>
            <td class="pb-50">
              {{ credorData.data.email }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="PhoneIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Telefone</span>
            </th>
            <td class="pb-50">
              {{ credorData.data.telefone }}
            </td>
          </tr>
          <tr>
            <th>
              <feather-icon
                icon="InfoIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Situação</span>
            </th>
            <td>
              {{ credorData.data.situacao }}
            </td>
          </tr>
        </table>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BButton, BAvatar, BRow, BCol,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'

export default {
  components: {
    BCard, BButton, BRow, BCol, BAvatar,
  },
  props: {
    credorData: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return {
      avatarText
    }
  },
}
</script>

<style>

</style>
