<template>
  <div>

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="credorData === undefined"
    >
      <h4 class="alert-heading">
        Erro ao acessar dados do Credor
      </h4>
      <div class="alert-body">
        Nenhum credor encontrado. Verifique em
        <b-link
          class="alert-link"
          :to="{ name: 'credor-list'}"
        >
          Lista Credores
        </b-link>
       para acessar.
      </div>
    </b-alert>

    <template v-if="credorData">
      <!-- First Row -->
      <b-row>
        <b-col
          cols="12"
          xl="9"
          lg="8"
          md="7"
        >
          <CredorInfoCard :credor-data="credorData" />
        </b-col>
        <!-- <b-col
          cols="12"
          md="5"
          xl="3"
          lg="4"
        >
          <CredorInfoPlano />
        </b-col> -->
      </b-row>

      <b-row>
        <b-col
          cols="12"
          lg="6"
        >
          <CredorStatsCount :credor-id="credorId" />
        </b-col>
        <b-col
          cols="12"
          lg="6"
        >
          <CredorUsuario :credor-data="credorData"/>
        </b-col>
      </b-row>

      <b-row>
        <b-col
          cols="12"
          lg="12"
        >
          <CredorDividas :credor-id="credorId" />
        </b-col>
      </b-row>

    </template>

  </div>
</template>

<script>
import store from '@/store'
import router from '@/router'
import { ref, onUnmounted } from '@vue/composition-api'
import {
  BRow, BCol, BAlert, BLink,
} from 'bootstrap-vue'

import credorStoreModule from '../credorStoreModule'
import CredorInfoCard from './CredorInfoCard.vue'
import CredorInfoPlano from './CredorInfoPlano.vue'
import CredorStatsCount from './CredorStatsCount.vue'
import CredorDividas from './CredorDividas.vue'
import CredorUsuario from './CredorUsuario.vue'

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,
    // Local Components
    CredorInfoCard,
    CredorInfoPlano,
    CredorStatsCount,
    CredorDividas,
    CredorUsuario
},

  setup() {
    const credorData = ref(null)
    const credorId = ref(null)

    const CREDOR_APP_STORE_MODULE_NAME = 'app-credor'

    // Register module
    if (!store.hasModule(CREDOR_APP_STORE_MODULE_NAME)) store.registerModule(CREDOR_APP_STORE_MODULE_NAME, credorStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CREDOR_APP_STORE_MODULE_NAME)) store.unregisterModule(CREDOR_APP_STORE_MODULE_NAME)
    })

    store.dispatch('app-credor/fetchCredor', { id: router.currentRoute.params.id })
      .then(response => { credorData.value = response.data; credorId.value = parseInt(router.currentRoute.params.id)})
      .catch(error => {
        if (error.response.status === 404) {
          credorData.value = undefined
        }
      })

    return {
      credorData,
      credorId
    }
  }
}
</script>

<style>

</style>
