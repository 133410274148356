<template>
  <b-card no-body border-variant="secondary" bg-variant="transparent" class="shadow-none">
    <b-card-header>
      <b-card-title>Dívidas</b-card-title>
    </b-card-header>

    <b-overlay
      :show="showLoading"
      variant="transparent"
      opacity="1"
      blur="2px"
      rounded="sm"
    >
      <b-card-body v-if="titulosData">
        <b-row>
          <b-col lg="3" sm="6">
            <statistic-card-horizontal
              icon="DollarSignIcon"
              :statistic="titulosData.valor_total"
              statistic-title="Valor total"
            />
          </b-col>
          <b-col lg="3" sm="6">
            <statistic-card-horizontal
              icon="DollarSignIcon"
              :statistic="titulosData.valor_medio"
              statistic-title="Valor médio"
            />
          </b-col>
          <b-col xl="2" md="4" sm="6">
            <statistic-card-vertical
              icon="CalendarIcon"
              :statistic="titulosData.condition_1"
              statistic-title="Vencidos < 90 dias"
              color="success"
            />
          </b-col>
          <b-col xl="2" md="4" sm="6">
            <statistic-card-vertical
              color="warning"
              icon="CalendarIcon"
              :statistic="titulosData.condition_2"
              statistic-title="Vencidos > 90 dias"
            />
          </b-col>
          <b-col xl="2" md="4" sm="6">
            <statistic-card-vertical
              color="danger"
              icon="CalendarIcon"
              :statistic="titulosData.condition_3"
              statistic-title="Vencidos > 180 dias"
            />
          </b-col>
        </b-row>
      </b-card-body>
    </b-overlay>
  </b-card>
</template>

<script>
import {
  BRow, BCol, BCard, BCardHeader, BCardTitle, BCardBody, BOverlay
} from 'bootstrap-vue'
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'
import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'
import axios from '@axios'
import { ref } from '@vue/composition-api'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BOverlay,
    StatisticCardHorizontal,
    StatisticCardVertical
  },
  props: {
    credorId: {
      type: Number,
      required: true,
    },
  },
  setup(props) {

    const titulosData = ref(null)
    const showLoading = ref(true)

    axios.get('credor/'+props.credorId+'/dividas')
      .then(response => { titulosData.value = response.data; showLoading.value = false })
      .catch(error => console.log(error))
    
    return {
      titulosData,
      showLoading
    }
  },
}
</script>
