<template>
  <b-card
    no-body
    class="border-primary"
  >
    <b-card-header class="d-flex justify-content-between align-items-center pt-75 pb-25">
      <h5 class="mb-0">
          Usuário para acesso
      </h5>
      <b-badge variant="light-primary">
        LOGIN
      </b-badge>
    </b-card-header>

    <b-card-body v-if="!credorData.data.usuario && !credorPass">
      <ul class="list-unstyled my-1">
        <li>
          <span class="align-middle">Usuário ainda não configurado</span>
        </li>
      </ul>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        block
       @click="newUser"
      >
        Criar usuário para credor
      </b-button>
    </b-card-body>
    <b-card-body v-else>
      <ul class="list-unstyled my-1">
        <li>
          <span class="align-middle" v-if="credorData.data.usuario"><strong>Código Login: </strong>{{ credorData.data.usuario }}</span>
          <span class="align-middle" v-else><strong>Código Login: </strong>{{ credorLogin }}</span>
        </li>
         <li>
          <span class="align-middle"><strong>Senha: </strong>
            <span v-if="credorPass == null">********</span>
            <h4 v-else> 
              <b-badge variant="success">
                {{ credorPass }}
              </b-badge> </h4>
          </span>
        </li>
      </ul>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        block
        @click="newPass"
      >
        Gerar nova senha
      </b-button>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="outline-primary"
        block
        @click="showModalSendMail"
      >
        Enviar por email
      </b-button>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BBadge, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ref }  from '@vue/composition-api'
import axios from '@axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BBadge,
    BButton,
  },
  props: {
    credorData: {
      type: Object,
      required: true,
    },
  },

  setup(props) {
    const credorPass = ref(null)
    const credorLogin = ref(null)

    function  newUser() {
      axios.post('credor/'+props.credorData.data.id+'/novoUsuario')
      .then(response => { 
        credorPass.value = response.data.pass
        credorLogin.value = response.data.credor.codigo
      })
      .catch(error => console.log(error))      
    }

    function newPass() {
      axios.post('credor/'+props.credorData.data.id+'/novaSenha')
      .then(response => { 
        credorPass.value = response.data.pass
      })
      .catch(error => console.log(error))      
    }

    function showModalSendMail() {
      this.$bvModal
      .msgBoxConfirm('Confirmar o envio do email com instruções?', {
        title: 'Confirmar ação',
        size: 'sm', 
        okVariant: 'primary',
        okTitle: 'Sim, enviar',
        cancelTitle: 'Cancelar',
        cancelVariant: 'outline-secondary',
        hideHeaderClose: false,
        centered: true,
      })
      .then(value => {
        if(value){
          axios.post('credor/'+props.credorData.data.id+'/emailInstrucoes', {
            credorPass: credorPass.value
          })
          .then(response => { 
            console.log(response.data)
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Email de instruções',
                icon: 'MailIcon',
                variant: 'success',
                text: `Email enviado com sucesso!`,
              }
            })
          })
          .catch(error => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Email de instruções',
                icon: 'XIcon',
                variant: 'danger',
                text: `Falha no envio ):`,
              },
            })
          })     
        }          
      })
    }

    return {
      credorPass,
      credorLogin,
      newUser,
      newPass,
      showModalSendMail
    }
  },
}
</script>

<style>

</style>
